import { signInWithEmailAndPassword, getAuth, signOut, Auth, browserLocalPersistence, getIdTokenResult, ParsedToken, onAuthStateChanged } from "firebase/auth";
import { FirebaseApp } from "@firebase/app";
import { ILoginArgs, IUser, IAuthContext } from "../interfaces";
import { AxiosInstance } from "axios";

export default class FirebaseAuth {

  auth: Auth;
  axios: AxiosInstance;

  constructor(firebaseApp: FirebaseApp, axios: AxiosInstance) {
    this.auth = getAuth(firebaseApp);
    this.axios = axios;

    this.auth.setPersistence(browserLocalPersistence);
    this.auth.useDeviceLanguage();

    this.getAuthProvider = this.getAuthProvider.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.getUserIdentity = this.getUserIdentity.bind(this);
    this.handleCheckAuth = this.handleCheckAuth.bind(this);
    this.getPermissions = this.getPermissions.bind(this);
  }

  public async handleLogOut() {
    await signOut(this.auth);
    localStorage.removeItem("auth")
    this.axios.defaults.headers.common = {
      Authorization: "",
    };
  }

  public async handleLogIn({ username, password, remember }: ILoginArgs) {
    try {
      if (this.auth) {
        const userCredential = await signInWithEmailAndPassword(this.auth, username, password);
        const userToken = await userCredential?.user?.getIdToken?.();
        if (userToken) {
          if (remember) localStorage.setItem("auth", userToken);
        } else {
          return Promise.reject();
        }
      } else {
        return Promise.reject();
      }
    } catch (error) {
      console.error(error)
      return Promise.reject(error);
    }
  }

  private async isCurrentUser(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(this.auth, (user) => {
        resolve(user ? true : false)
      })
    })
  }

  private async getUserIdentity(): Promise<IUser> {
    await this.isCurrentUser();
    const user = this.auth?.currentUser;
    return {
      ...this.auth.currentUser,
      email: user?.email || "",
      name: user?.displayName || ""
    };
  }

  private async handleCheckAuth() {
    if (await this.isCurrentUser() && this.auth?.currentUser) {
      const idTokenResult = await getIdTokenResult(this.auth.currentUser);
      this.axios.defaults.headers.common = {
        Authorization: `Bearer ${idTokenResult.token}`,
      };
      return Promise.resolve()
    } else {
      return Promise.reject("User is not found in Check");
    }
  }

  public async getPermissions(): Promise<ParsedToken> {
    if (await this.isCurrentUser() && this.auth?.currentUser) {
      const idTokenResult = await getIdTokenResult(this.auth.currentUser);
      return idTokenResult?.claims
    } else {
      return Promise.reject("User is not found in Permissions");
    }
  }

  public getAuthProvider(): IAuthContext {
    return {
      login: this.handleLogIn,
      logout: this.handleLogOut,
      checkAuth: this.handleCheckAuth,
      checkError: () => Promise.resolve(),
      getPermissions: this.getPermissions,
      getUserIdentity: this.getUserIdentity,
    };
  }
}