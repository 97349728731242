import React from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  useDataGrid,
  DataGrid,
  GridToolbar,
  GridColumns,
  List,
  EditButton,
  DateField,
  BooleanField,
} from "@pankod/refine-mui";

import { IUserC } from "interfaces";
import { Check, Close } from "@mui/icons-material";

const getStatusTranslate = (status: string): string => {
  switch (status) {
    case 'IN REVIEW':
      return 'Por revisar'
    case 'APPROVED':
      return 'Aprobado'
    case 'REJECTED':
      return 'Rechazado'
    default:
      return '';
  }
}

export const UsersList: React.FC = () => {
  const t = useTranslate();

  const { dataGridProps } = useDataGrid<IUserC>();

  const columns: GridColumns<IUserC> = [
    {
      field: "name",
      headerName: t("users.fields.name"),
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "lastName",
      headerName: t("users.fields.lastname"),
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "email",
      headerName: t("users.fields.email"),
      renderCell: function render({ row }) {
        return <p>{row.email}</p>;
      },
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "phone",
      headerName: t("users.fields.phone"),
      renderCell: function render({ row }) {
        return <p>{row.phone}</p>;
      },
      maxWidth: 130,
      flex: 0.2,
    },
    {
      field: "role",
      headerName: t("users.fields.role"),
      renderCell: function render({ row }) {
        return <p>{row.role}</p>;
      },
      maxWidth: 70,
      flex: 0.2,
    },
    {
      field: "metadata.isValidHost",
      headerName: t("users.fields.valid"),
      renderCell: function render({ row }) {
        if (row.role === 'GUEST') return "";
        return <BooleanField
          value={row.metadata?.isValidHost}
          trueIcon={<Check />}
          falseIcon={<Close />}
        />;
      },
      maxWidth: 100,
      flex: 0.2,
    },
    {
      field: "metadata.documents",
      headerName: t("users.fields.documents"),
      renderCell: function render({ row }) {
        if (row.role === 'GUEST') return "";
        if (!row.documents && !row.documents[0]) return "";
        return <p>{getStatusTranslate(row.documents[0].status)}</p>;
      },
      maxWidth: 120,
      flex: 0.2,
    },
    {
      field: "updatedAt",
      headerName: t("users.fields.updatedAt"),
      renderCell: function render({ row }) {
        return <DateField value={row.updatedAt} format="DD/MM/YYYY H:mm" />;
      },
      maxWidth: 150,
      flex: 0.2,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("table.actions"),
      renderCell: function render({ row }) {
        if (row.role === 'GUEST') return "";
        return <EditButton hideText recordItemId={row.id} />;
      },
      align: "center",
      headerAlign: "center",
      minWidth: 80,
    }
  ];

  /*const gridColumns = React.useMemo<GridColumns<IUserC>>(
    () => columns,
    [columns]
  );*/

  return (
    <List canCreate={false}>
      <DataGrid 
        {...dataGridProps} 
        columns={columns} 
        components={{
          Toolbar: GridToolbar,
        }}
        autoHeight />
    </List>
  );
};
