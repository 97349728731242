import { useEffect, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  Box,
  TextField,
  Switch,
  Typography,
  FormControlLabel,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { IAdvertisement } from "interfaces";


export const AdvertisementEdit: React.FC = () => {
  const t = useTranslate();
  const [valid, setValid] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const {
    saveButtonProps,
    register,
    refineCore: { queryResult }
  } = useForm();

  const advertisement: IAdvertisement | undefined = queryResult?.data?.data as IAdvertisement;

  useEffect(() => {
    if(advertisement) {
      setValid(!(advertisement.status === 'Rejected' || advertisement.status === 'On Review'))
      setMessage(advertisement.message)
    }
  }, [advertisement])

  if (!advertisement) return (
    <Box
      component="div"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h6">Cargando anuncio...</Typography>
    </Box>
  );

  return (
    <Edit saveButtonProps={saveButtonProps} cardHeaderProps={{
      title: <Typography variant="h5">{t("actions.documentsReview")}</Typography>
    }} >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >        
        <Typography variant="h6">{advertisement.name}</Typography>
        {
          advertisement.documentsUrls.map((document, i) => (
            <iframe key={i} src={document} title={i.toString()} style={{ minHeight: '80vh', marginTop: '15px', borderTop: '10px dotted black'}}/>
          ))
        }
        <FormControlLabel control={<Switch {...register('approved')} checked={valid} onChange={(e) => setValid(e.target.checked)}/>} label="Aprobado"/>
        { !valid && 
          <TextField
            {...register("message", {
              required: t("errors.required.field", { field: "Motivo de rechazo" }),
            })}
            margin="normal"
            name="message"
            placeholder="Motivo de rechazo"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        }
      </Box>
    </Edit>
  );
};
