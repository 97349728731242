import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { ILoginArgs } from 'interfaces';
import { useLogin, useTranslate } from '@pankod/refine-core';

const theme = createTheme();

export function SignIn() {
  const t = useTranslate();
  const { mutate: login, isLoading } = useLogin<ILoginArgs>();
  const { register, handleSubmit, formState: { errors } } = useForm<ILoginArgs>();

  const onLogin = (args: ILoginArgs) => {
    login(args)
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          { t("pages.login.title") }
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onLogin)} noValidate sx={{ mt: 1 }}>
            <TextField {...register('username', { required: true })}
              margin="normal"
              fullWidth
              label={ t("pages.login.username") }
              autoComplete="email"
              autoFocus
            />
            <Typography variant="caption" display="block" gutterBottom color="secondary.main">
              {errors.username && t("errors.required.field")}
            </Typography>
            <TextField {...register('password', { required: true })}
              margin="normal"
              fullWidth
              label={ t("pages.login.password") }
              type="password"
              autoComplete="current-password"
            />
            <Typography variant="caption" display="block" gutterBottom color="secondary.main">
              {errors.username && t("errors.required.field")}
            </Typography>
            <FormControlLabel
              control={<Checkbox {...register('remember')} color="primary" />}
              label={ t("pages.login.remember") }
            />
            <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              { t("pages.login.signin") }
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}