import React from "react";
import { useTranslate } from "@pankod/refine-core";
import { Box, DataGrid, DateField, EditButton, GridColumns, List, useDataGrid } from "@pankod/refine-mui";
import { ISetting } from "interfaces";

export const SettingsList: React.FC = (props: any) => {
  const t = useTranslate();

  const { dataGridProps } = useDataGrid<ISetting>();

  const columns = React.useMemo<GridColumns<ISetting>>(
    () => [
      {
        field: "iva",
        headerName: t("settings.fields.iva"),
        minWidth: 50,
        flex: 0.2,
      },
      {
        field: "hostCommission",
        headerName: t("settings.fields.hostCommission"),
        minWidth: 80,
        flex: 0.2,
      },
      {
        field: "guestCommission",
        headerName: t("settings.fields.guestCommission"),
        minWidth: 80,
        flex: 0.2,
      },
      {
        field: "updatedAt",
        headerName: t("settings.fields.updatedAt"),
        renderCell: function render({ row }) {
          return <DateField value={row.updatedAt} format="DD/MM/YYYY HH:mm" />;
        },
        minWidth: 50,
        flex: 0.2,
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "row" }}
              autoComplete="off"
            >
              <EditButton hideText recordItemId={row.id} />
            </Box>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [t]
  );

  return (
    <List>
      <DataGrid 
      {...dataGridProps} 
      columns={columns}
      autoHeight />
    </List>
  );
};