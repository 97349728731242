import React from "react";
import { Box, Edit, TextField, Typography } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { useTranslate } from "@pankod/refine-core";
import { ISetting } from "interfaces";

export const SettingsEdit: React.FC = (props: any) => {
  const t = useTranslate();
  const {
    saveButtonProps,
    register,
    refineCore: { queryResult }
  } = useForm();

  const settings: ISetting | undefined = queryResult?.data?.data as ISetting;
  if (!settings) return <div></div>;
  const Form = () => {
    return (
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography variant="h6">{t('settings.fields.iva')}</Typography>
        <TextField
          {...register("iva")}
          name="iva"
          type="number"
          margin="normal"
        />
        <Typography variant="h6">{t('settings.fields.hostCommission')}</Typography>
        <TextField
          {...register("hostCommission")}
          name="hostCommission"
          type="number"
          margin="normal"
        />
        <Typography variant="h6">{t('settings.fields.guestCommission')}</Typography>
        <TextField
          {...register("guestCommission")}
          name="guestCommission"
          type="number"
          margin="normal"
        />
      </Box>
    )
  }

  return (
    <Edit title={<Typography variant="h5">Editar variables de operación</Typography>} saveButtonProps={saveButtonProps}>
      <Form/>
    </Edit>
  )
};
