import React from "react";
import { useTranslate } from "@pankod/refine-core";
import { Box, DataGrid, DateField, GridColumns, GridToolbar, List, ShowButton, useDataGrid } from "@pankod/refine-mui";
import { IBooking, ICatalog } from "interfaces";

export const BookingsList: React.FC = (props: any) => {
  const t = useTranslate();

  const { dataGridProps } = useDataGrid<ICatalog>();

  const columns = React.useMemo<GridColumns<IBooking>>(
    () => [
      {
        field: "id",
        headerName: t("bookings.fields.id"),
        minWidth: 100,
        flex: 0.2,
      },
      {
        field: "date",
        headerName: t("bookings.fields.date"),
        renderCell: function render({ row }) {
          return <DateField value={row.date.substring(0,10)} format="DD/MM/YYYY"  />;
        },
        minWidth: 50,
        flex: 0.2,
      },
      {
        field: "time",
        headerName: t("bookings.fields.time"),
        minWidth: 80,
        flex: 0.2,
      },
      {
        field: "status",
        headerName: t("bookings.fields.status"),
        minWidth: 80,
        flex: 0.2,
      },
      {
        field: "createdAt",
        headerName: t("bookings.fields.createdAt"),
        renderCell: function render({ row }) {
          return <DateField value={row.date} format="DD/MM/YYYY" />;
        },
        minWidth: 50,
        flex: 0.2,
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "row" }}
              autoComplete="off"
            >
              <ShowButton hideText recordItemId={row.id} />
            </Box>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [t]
  );

  return (
    <List>
      <DataGrid 
      {...dataGridProps} 
      columns={columns} 
      components={{
        Toolbar: GridToolbar,
      }}
      autoHeight />
    </List>
  );
};