import React from "react";
import { Box, Create, Edit, TextField, Typography } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { useTranslate } from "@pankod/refine-core";
import { ICatalog } from "interfaces";

const getTitle = (label: string): string => {
  switch (label) {
    case 'Tipos de espacio':
      return 'Tipo de espacio';
    case 'Tipos de evento':
      return 'Tipo de evento';
    case 'Amenidades':
      return 'Amenidad';
    case 'Tags':
      return 'Tag'
    default:
      return '';
  }
}

export const CatalogCU: React.FC = (props: any) => {
  const t = useTranslate();
  const {
    saveButtonProps,
    register,
    refineCore: { queryResult }
  } = useForm();

  const catalog: ICatalog | undefined = queryResult?.data?.data as ICatalog;
  const Form = () => {
    return (
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography variant="h6">{t('catalogs.fields.value')}</Typography>
        <TextField
          {...register("value")}
          margin="normal"
          name="value"
        />
      </Box>
    )
  }

  return catalog ? (
    <Edit title={<Typography variant="h5">Editar {getTitle(props.options.label)}</Typography>} saveButtonProps={saveButtonProps}>
      <Form/>
    </Edit>
  ):(
    <Create title={<Typography variant="h5">Crear {getTitle(props.options.label)}</Typography>} saveButtonProps={saveButtonProps}>
      <Form/>
    </Create>
  );
};