import React from "react";
import { useTranslate } from "@pankod/refine-core";
import { Box, DataGrid, DateField, DeleteButton, GridColumns, GridToolbar, List, useDataGrid } from "@pankod/refine-mui";
import { ICatalog } from "interfaces";

export const CatalogList: React.FC = (props: any) => {
  const t = useTranslate();

  const { dataGridProps } = useDataGrid<ICatalog>();

  const columns = React.useMemo<GridColumns<ICatalog>>(
    () => [
      {
        field: "value",
        headerName: t("catalogs.fields.value"),
        renderCell: function render({ row }) {
          return <p>{row.value}</p>;
        },
        minWidth: 100,
        flex: 0.2,
      },
      {
        field: "updatedAt",
        headerName: t("catalogs.fields.updatedAt"),
        renderCell: function render({ row }) {
          return <DateField value={row.updatedAt} format="DD/MM/YYYY H:mm" />;
        },
        minWidth: 50,
        flex: 0.2,
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "row" }}
              autoComplete="off"
            >
              {/*<EditButton hideText recordItemId={row.id} />*/}
              <DeleteButton hideText recordItemId={row.id} />
            </Box>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [t]
  );

  return (
    <List>
      <DataGrid 
      {...dataGridProps} 
      columns={columns} 
      components={{
        Toolbar: GridToolbar,
      }}
      autoHeight />
    </List>
  );
};