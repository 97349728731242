import React from "react";

import { useShow } from "@pankod/refine-core";
import { Show, Stack, Typography } from "@pankod/refine-mui";
import {  } from "@pankod/refine-mui";

const formatNumber = (value: number): string => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN', minimumFractionDigits: 2  }).format(value)
}

export const BookingShow = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading} title={<Typography variant="h5">Reservación {record?.id ?? ""}</Typography>}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    Stripe
                </Typography>
                <Typography variant="body2">
                    Payment Intent: {record?.paymentIntent}
                </Typography>
            </Stack>
            <Stack gap={1} marginTop={3}>
                <Typography variant="body1" fontWeight="bold">
                    Espacio
                </Typography>
                <Typography variant="body2">
                    Id: {record?.advertisement.id}
                </Typography>
                <Typography variant="body2">
                    Nombre: {record?.advertisement.name}
                </Typography>
                <Typography variant="body2">
                    Dirección: {record?.advertisement.address.fullAddress}
                </Typography>
            </Stack>
            {
                record?.package ? 
                <Stack gap={1} marginTop={3}>
                    <Typography variant="body1" fontWeight="bold">
                        Paquete
                    </Typography>
                    <Typography variant="body2">
                        Id: {record?.package.id}
                    </Typography>
                    <Typography variant="body2">
                        Nombre: {record?.package.name}
                    </Typography>
                    <Typography variant="body2">
                        Precio: ${record?.package.price}
                    </Typography>
                </Stack>
                : ''
            }
            <Stack gap={1} marginTop={3}>
                <Typography variant="body1" fontWeight="bold">
                    Reservación
                </Typography>
                <Typography variant="body2">
                    Estado: {record?.status}
                </Typography>
                <Typography variant="body2">
                    Fecha: {record?.date.replace('T00:00:00.000Z','')}
                </Typography>
                <Typography variant="body2">
                    Hora: {record?.time}
                </Typography>
                <Typography variant="body2">
                    invitados: {record?.people}
                </Typography>
                <Typography variant="body2">
                    Dirección: {record?.advertisement.address.fullAddress}
                </Typography>
            </Stack>
            <Stack gap={1} marginTop={3}>
                <Typography variant="body1" fontWeight="bold">
                    Anfitrión
                </Typography>
                <Typography variant="body2">
                    Id: {record?.host.id}
                </Typography>
                <Typography variant="body2">
                    nombre: {record?.host.name} {record?.host.lastName}
                </Typography>
                <Typography variant="body2">
                    Correo: {record?.host.email}
                </Typography>
                <Typography variant="body2">
                    Teléfono: {record?.host.phone}
                </Typography>
            </Stack>
            <Stack gap={1} marginTop={3}>
                <Typography variant="body1" fontWeight="bold">
                    Festejado
                </Typography>
                <Typography variant="body2">
                    Id: {record?.guest.id}
                </Typography>
                <Typography variant="body2">
                    nombre: {record?.guest.name} {record?.guest.lastName}
                </Typography>
                <Typography variant="body2">
                    Correo: {record?.guest.email}
                </Typography>
                <Typography variant="body2">
                    Teléfono: {record?.guest.phone}
                </Typography>
            </Stack>
            <Stack gap={1} marginTop={3}>
                <Typography variant="body1" fontWeight="bold">
                    Precios:
                </Typography>
                <Typography variant="body2">
                    Espacio: {formatNumber(record?.price)}
                </Typography>
                {
                    record?.package ?
                    <Typography variant="body2">
                        Paquete: {formatNumber(record?.package.price)}
                    </Typography>
                    : ''
                }
                
                <br/>
                <Typography variant="body2" fontWeight="bold">
                    Servicios con costo:
                </Typography>
                { record?.paidServices.map((paidService: any) => {
                    return (
                        <Typography id={paidService.name} variant="body2">
                            {paidService.name}: {formatNumber(paidService.total)} ({formatNumber(paidService.price)} x {paidService.isByPerson ? record?.people : 1})
                        </Typography>
                    )
                })}
                <br/>
                <Typography variant="body2">
                    Subtotal: {formatNumber(record?.subtotal)}
                </Typography>
                <Typography variant="body2">
                    IVA: {formatNumber(record?.ivaAmount)} ({record?.iva})
                </Typography>
                <br/>
                <Typography variant="body2">
                    Comisión Festejado: {formatNumber(record?.guestCommissionAmount)} ({record?.guestCommission})
                </Typography>
                <Typography variant="body2">
                    IVA Comisión Festejado: {formatNumber(record?.guestCommissionIvaAmount)} ({record?.iva})
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                    Cobro a Festejado: {formatNumber(record?.total)}
                </Typography>
                <br/>
                <Typography variant="body2">
                    Comisión Anfitrión: {formatNumber(record?.hostCommissionAmount)} ({record?.hostCommission})
                </Typography>
                <Typography variant="body2">
                    IVA Comisión Anfitrión: {formatNumber(record?.hostCommissionIvaAmount)} ({record?.iva})
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                    Pago a Anfitrión: {formatNumber(record?.hostFee)}
                </Typography>
                <br/>
                <Typography variant="body2">
                    Ingreso Comisión iFiesta: {formatNumber(Math.round(((record?.guestCommissionAmount + record?.hostCommissionAmount) + Number.EPSILON) * 100) / 100)}
                </Typography>
                <Typography variant="body2">
                    Ingreso IVA iFiesta: {formatNumber(Math.round(((record?.guestCommissionIvaAmount + record?.hostCommissionIvaAmount) + Number.EPSILON) * 100) / 100)}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                    Ingreso Total iFiesta: {formatNumber(Math.round(((record?.total - record?.hostFee) + Number.EPSILON) * 100) / 100)}
                </Typography>
            </Stack>
        </Show>
    );
};