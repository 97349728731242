import { useState, useEffect } from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  Box,
  TextField,
  Switch,
  Typography,
  FormControlLabel,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import ReactImageMagnify from '@blacklab/react-image-magnify';
import { IDocument } from "interfaces";

interface Dimensions {
  width: number;
  height: number;
}

const getImageDimensions = async (url: string): Promise<Dimensions> => {
  return new Promise<Dimensions>((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      const image = this as unknown as Dimensions
      resolve({ width: image.width, height: image.height});
    };
    img.src = url;
  });
}

export const UsersEdit: React.FC = () => {
  const t = useTranslate();
  const [valid, setValid] = useState<boolean>(false)
  const [dimensionsFront, setDimensionsFront] = useState<Dimensions>({ width: 0, height: 0 })
  const [dimensionsBack, setDimensionsBack] = useState<Dimensions>({ width: 0, height: 0 })
  const {
    saveButtonProps,
    register,
    refineCore: { queryResult }
  } = useForm();

  const host: IDocument | undefined = queryResult?.data?.data as IDocument;
  useEffect(() => {
    if(host) {
      setValid(host.status === 'APPROVED')
    }
    if(host && host.officialId){
      getImageDimensions(host.officialId.urlFront).then(setDimensionsFront);
      getImageDimensions(host.officialId.urlBack).then(setDimensionsBack);
    }
  }, [host])

  if (!host) return (
    <Box
      component="div"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h6">El anfitrión no ha cargado documentos aun.</Typography>
    </Box>
  );

  return (
    <Edit saveButtonProps={saveButtonProps} cardHeaderProps={{
      title: <Typography variant="h5">{t("actions.documentsReview")}</Typography>
    }} >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        
        <Box
          component="div"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography variant="h6">RFC</Typography>
          <TextField
            {...register("rfc.value")}
            margin="normal"
            name="rfc"
            disabled
          />
        </Box>
        <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column", marginTop: 5 }}
          >
          <Typography variant="h6">Identificación Oficial</Typography>
          <Box
            component="div"
            sx={{ display: "flex", flexDirection: "row", gap: 2 }}
          >
            {
              host.officialId?.typeFileFront === 'application/pdf' ?
                <embed
                  type="application/pdf"
                  width="500"
                  src={`${host.officialId?.urlFront!}#toolbar=0`}
                /> :
                <ReactImageMagnify
                  activationInteractionHint="hover"
                  imageProps={{
                    alt: "Frontal",
                    src: host.officialId.urlFront,
                    width: 500
                  }}
                  magnifiedImageProps={{
                    src: host.officialId.urlFront,
                    width: dimensionsFront.width,
                    height: dimensionsFront.height
                  }}
                  style={{ marginRight: 20, border: "2px double" }}
                />
            }
            {
              host.officialId?.typeFileBack === 'application/pdf' ?
                <embed
                  type="application/pdf"
                  width="500"
                  src={`${host.officialId?.urlBack!}#toolbar=0`}
                /> :
                <ReactImageMagnify
                  activationInteractionHint="hover"
                  imageProps={{
                    alt: "Back",
                    src: host.officialId.urlBack,
                    width: 500
                  }}
                  magnifiedImageProps={{
                    src: host.officialId.urlBack,
                    width: dimensionsBack.width,
                    height: dimensionsBack.height
                  }}
                  style={{ marginRight: 20, border: "2px double" }}
                />
            }
          </Box>
        </Box>
        <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column", marginTop: 5 }}
          >
          <Typography variant="h6">¿Aprobar?</Typography>
          <FormControlLabel control={<Switch {...register('approved')} checked={valid} onChange={(e) => setValid(e.target.checked)}/>} label="Aprobado"/>
          { !valid && 
            <TextField
              {...register("message", {
                required: t("errors.required.field", { field: "Motivo de rechazo" }),
              })}
              margin="normal"
              name="message"
              placeholder="Motivo de rechazo"
            />
          }
        </Box>
      </Box>
    </Edit>
  );
};
